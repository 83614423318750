import HomeIcon from "@mui/icons-material/Home"
// import GroupIcon from "@mui/icons-material/Group"
// import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"
// import AccountTreeIcon from "@mui/icons-material/AccountTree"
// import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"
// import BiotechIcon from "@mui/icons-material/Biotech"

export const APP_LAYOUT = {
  APP_HEADER_HEIGHT: 64,
}

export const ROUTE_PATH = {
  HOME: "/",
  CLIENTS: "/clients",
  CONVERSATIONAL_AI: "/conversational-ai",
  USE_CASES: "/use-cases",
  AWARDS: "/awards",
  TESTIMONIALS: "/testimonials",
  BOOKDEMO: "/bookdemo",
}

export const APP_PAGE = {
  HOME: "Home",
  CLIENTS: "Clients",
  CONVERSATIONAL_AI: "Conversational AI",
  USE_CASES: "Use Cases",
  AWARDS: "Awards",
  TESTIMONIALS: "Testimonials",
  BOOKDEMO: "Book A Demo",
}

export const DOCUMENT_TITLE ={
  HOME: "Ori AI: Schedule sales-enabled conversational AI demo now",
  BOOKDEMO: "Elevate CX by leveraging Ori AI's voice & chat-based omnichannel chatbots like whatsapp bot, instagram chatbot etc. Schedule your conversational AI demo now."
}

export const THEME_TYPE = {
  DARK: "dark",
  LIGHT: "light",
}

export const LOCAL_STORAGE = {
  THEME: "portfolioTheme",
  PRIMARY: "themePrimary",
  SECONDARY: "themeSecondary",
}

export const NAV_BAR = [
  {
    name: APP_PAGE.HOME,
    route: ROUTE_PATH.HOME,
    Icon: HomeIcon,
  },
  // {
  //   name: APP_PAGE.CLIENTS,
  //   route: ROUTE_PATH.CLIENTS,
  //   Icon: GroupIcon,
  // },
  // {
  //   name: APP_PAGE.CONVERSATIONAL_AI,
  //   route: ROUTE_PATH.CONVERSATIONAL_AI,
  //   Icon: QuestionAnswerIcon,
  // },
  // {
  //   name: APP_PAGE.USE_CASES,
  //   route: ROUTE_PATH.USE_CASES,
  //   Icon: AccountTreeIcon,
  // },
  // {
  //   name: APP_PAGE.AWARDS,
  //   route: ROUTE_PATH.AWARDS,
  //   Icon: EmojiEventsIcon,
  // },
  // {
  //   name: APP_PAGE.TESTIMONIALS,
  //   route: ROUTE_PATH.TESTIMONIALS,
  //   Icon: BiotechIcon,
  // },
]
