import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import TwitterIcon from "@mui/icons-material/Twitter"
import YoutubeIcon from "@mui/icons-material/YouTube"

import {
  IMG_BRAND1,
  IMG_BRAND2,
  IMG_BRAND3,
  IMG_BRAND4,
  IMG_BRAND5,
  IMG_BRAND6,
  IMG_BRAND7,
  IMG_BRAND8,
  IMG_BRAND9,
  IMG_BRAND10,
  IMG_BRAND11,
  IMG_BRAND12,
  IMG_BRAND13,
  IMG_BRAND14,
  IMG_BRAND18,
  IMG_BRAND4_DARK,
  IMG_BRAND10_DARK,
  IMG_BRAND13_DARK,
  IMG_BRAND14_DARK,
  IMG_CLIENT1,
  IMG_CLIENT2,
  IMG_CLIENT3,
  IMG_CLIENT5,
  VID_CONVERSATIONAL_AI_1,
  VID_CONVERSATIONAL_AI_1_DARK,
  VID_CONVERSATIONAL_AI_2,
  VID_CONVERSATIONAL_AI_2_DARK,
} from "../assets"

export const BRAND_INFO = {
  contactUsEmail: "contactus@oriserve.com",
  domainUrl: "www.oriserve.com",
  bookDemoBackendUrl: "https://ori-auto-demo.oriserve.com/bookADemo/",
  calendlyUrl:
    "https://calendly.com/sanyam-singh/30min?hide_gdpr_banner=1&hide_landing_page_details=1",
  googleTagManagerUrl: `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_KEY}`,
}

export const APP_FOOTER = {
  contents: [
    {
      title: "Why Ori AI",
      list: [
        {
          type: "link",
          text: "About",
          url: "https://www.oriserve.com/about-us",
        },
        {
          type: "link",
          text: "FAQ's",
          url: "https://www.oriserve.com/faqs",
        },
        {
          type: "link",
          text: "Careers",
          url: "https://www.oriserve.com/careers",
        },
      ],
    },
    {
      title: "Platform",
      list: [
        {
          type: "link",
          text: "Enterprise Grade Security",
          url: "https://www.oriserve.com/faqs",
        },
      ],
    },
    {
      title: "Channels",
      list: [
        {
          type: "link",
          text: "Whatsapp",
          url: "https://www.oriserve.com/channels-whatsapp",
        },
        {
          type: "link",
          text: "Facebook Messenger",
          url: "https://www.oriserve.com/channels-facebook",
        },
        {
          type: "link",
          text: "Google Business Messaging",
          url: "https://www.oriserve.com/googlebusinessmessages",
        },
        {
          type: "link",
          text: "AdSter",
          url: "https://www.oriserve.com/blog/conversational-ai-and-business-messages-helping-brands-drive-conversions-and-retention",
        },
        {
          type: "link",
          text: "Instagram",
          url: "https://www.oriserve.com/channels-instagram-messenger",
        },
        // {
        //   type: "link",
        //   text: "Google RCS",
        // },
        // {
        //   type: "link",
        //   text: "In-App Bot",
        // },
      ],
    },
    {
      title: "Resources",
      list: [
        {
          type: "link",
          text: "Blog",
          url: "https://www.oriserve.com/blog",
        },
        {
          type: "link",
          text: "Case Studies",
          url: "https://www.oriserve.com/case-studies",
        },
        {
          type: "link",
          text: "White Papers",
          url: "https://www.oriserve.com/white-paper/conversational-ai-automobile",
        },
        {
          type: "link",
          text: "Use Cases",
          url: "https://www.oriserve.com/use-cases",
        },
      ],
    },
    {
      title: "Industries",
      list: [
        {
          type: "link",
          text: "Automotive",
          url: "https://www.oriserve.com/automobile/use-cases",
        },
        {
          type: "link",
          text: "Telecom",
          url: "https://www.oriserve.com/vodafone-idea-case-study",
        },
        {
          type: "link",
          text: "Education",
          url: "https://www.oriserve.com/edtech/use-cases",
        },
        {
          type: "link",
          text: "Banking & Finance",
          url: "https://www.oriserve.com/banking-finance-credit-cards/use-cases",
        },
        {
          type: "link",
          text: "Real Estate",
          url: "https://www.oriserve.com/real-estate/use-cases",
        },
        {
          type: "link",
          text: "Retail",
          url: "https://www.oriserve.com/retail/use-cases",
        },
        {
          type: "link",
          text: "Travel and Aviation",
          url: "https://www.oriserve.com/airlines/use-cases",
        },
      ],
    },
    {
      type: "header",
      title: "Get the latest from the world of conversational marketing",
    },
  ],
  socials: [
    {
      link: "https://www.facebook.com/oriserve",
      icon: <FacebookIcon />,
      label: "facebook",
    },
    {
      link: "https://www.instagram.com/oriserveai/",
      icon: <InstagramIcon />,
      label: "instagram",
    },
    {
      link: "https://www.linkedin.com/company/oriserve",
      icon: <LinkedInIcon />,
      label: "linkedin",
    },
    {
      link: "https://twitter.com/oriserve",
      icon: <TwitterIcon />,
      label: "twitter",
    },
    {
      link: "https://www.youtube.com/oriserve",
      icon: <YoutubeIcon />,
      label: "Youtube",
    },
  ],
  extras: [
    {
      type: "link",
      text: "Privacy Policy",
      url: "https://www.oriserve.com/privacy-policy",
    },
    {
      type: "link",
      text: "Information Security Policy",
      url: "https://www.oriserve.com/information-security-policy",
    },
  ],
}

export const TRUSTED_BY = {
  title: "TRUSTED BY THE BEST",
  subtitle: "Across sectors and geography",
  carousel: [
    {
      logo: IMG_BRAND1,
      logoDark: IMG_BRAND1,
      alt: "Auto",
      growth: "14x",
      text: "LIFT IN VISITORS TO LEAD CONVERSATIONS",
      title: "AUTO",
      description: "Test drive bookings",
    },
    {
      logo: IMG_BRAND2,
      logoDark: IMG_BRAND2,
      alt: "Retail",
      growth: "8.7%",
      text: "INCREMENT IN REVENUE",
      title: "RETAIL",
      description: "Cross Sell Driven Buying",
    },
    {
      logo: IMG_BRAND3,
      logoDark: IMG_BRAND3,
      alt: "Telecom",
      growth: "30%",
      text: "IMPROVEMENT",
      title: "TELECOM",
      description: "Straight Through Purchase",
    },
    {
      logo: IMG_BRAND4,
      logoDark: IMG_BRAND4_DARK,
      alt: "Fintech",
      growth: "9%",
      text: "UPLIFT IN APP ACTIVATION",
      title: "FINTECH",
      description: "Activation & Retention",
    },
  ],
}

export const AWARDS = {
  title: "AWARD BY THE BEST",
  cards: [
    {
      logo: IMG_BRAND5,
      alt: "Google",
      text: "Best Global AI Sales Bot 2021",
    },
    {
      logo: IMG_BRAND7,
      alt: "Microsoft",
      text: "AI for all global winner",
    },
    {
      logo: IMG_BRAND8,
      alt: "MMA Smarties",
      text: "Mobile Voice Award - APAC",
    },
    {
      logo: IMG_BRAND6,
      alt: "CXA'21",
      text: "Best Digital Customer Experience",
    },
  ],
}

export const CLIENT_TESTIMONY = {
  carousel: [
    {
      avatar: IMG_CLIENT1,
      name: "PRATEEK AGRAWAL",
      about: "Manager of CRM, 6thStreet",
      logo: IMG_BRAND14,
      logoDark: IMG_BRAND14_DARK,
      brandAlt: "6thStreet",
      testimony:
        "We successfully use Ori AI with WhatsApp as an engagement platform to win back lost customers, gather feedback from existing customers, and generate positive reviews from new customers to improve overall experiences. The returns are strong. We’ve doubled and even tripled revenue month over month with WhatsApp. ",
    },
    {
      avatar: IMG_CLIENT3,
      name: "SUNDAR PICHAI",
      about: "CHIEF EXECUTIVE OFFICER, GOOGLE",
      logo: IMG_BRAND5,
      logoDark: IMG_BRAND5,
      brandAlt: "Google",
      testimony:
        "Artificial intelligence is one of the most profound things we're working on as humanity. It is more profound than fire or electricity.",
    },
    {
      avatar: IMG_CLIENT2,
      name: "SHARDUL VERMA",
      about: "Head of Digital Marketing Bajaj Auto",
      logo: IMG_BRAND1,
      logoDark: IMG_BRAND1,
      brandAlt: "Bajaj",
      testimony:
        "Google's business messages, in combination with the ORI Revenue Acceleration platform, allowed us to assist our customers at the most important point of their customer journey: Their discovery phase. This in turn skyrocketed our overall visitor-to-lead conversions. ",
    },
    {
      avatar: IMG_CLIENT5,
      name: "JAGBIR SINGH",
      about: "Chief Technology Officer, VIL",
      logo: IMG_BRAND3,
      logoDark: IMG_BRAND3,
      brandAlt: "Voda",
      testimony:
        "The integration of VIC across platforms, including the award-winning Google Business Message integration, has helped us substantially enhance customer experience. We are happy to receive this recognition along with our technology partner, ORISERVE . Their global first automation stack is enabling us to stay at the forefront of industry-leading customer experiences, across languages & geographies, with agility. ",
    },
  ],
}

export const CONVERSATIONAL_AI = {
  title: "THE REAL CONVERSATIONAL AI",
  subtitle:
    "Increase conversions on your website, mobile application, WhatsApp, or social media DMs.",
  description:
    "(Ori works across your customer's preferred communication channels - Media)",
  cards: [
    {
      title: "1:1 product education",
      subtitle: "Instant and Relevant",
      description: "Lead capture and qualification",
    },
    {
      title: "Lead nurturing",
      subtitle: "Relevant and Persistent",
      description: "Reconnect and re-engage till closure",
    },
    {
      title: "Qualify Convert",
      subtitle: "Conversations to conversions",
      description: "Qualified Leads",
    },
  ],
  carousel: [
    {
      video: VID_CONVERSATIONAL_AI_1,
      darkVideo: VID_CONVERSATIONAL_AI_1_DARK,
    },
    {
      video: VID_CONVERSATIONAL_AI_2,
      darkVideo: VID_CONVERSATIONAL_AI_2_DARK,
      id: "VID_CONVERSATIONAL_AI_2",
    },
  ],
}

export const INDUSTRY_USE_CASES = {
  carousel: [
    {
      name: "TELECOM",
      logo: IMG_BRAND3,
      logoDark: IMG_BRAND3,
      alt: "Voda",
      growth: "30%",
      growthText: "Straight through purchase",
      data: [
        {
          title: "Revenue Acceleration",
          description:
            "Using CRM & behavioural data to sell new high-value packs, and create upsell + cross-sell opportunities. ",
        },
        {
          title: "Increase in lead conversions",
          description:
            " Improvement in visitors to order conversions through continuous learning & improvement. ",
        },
        {
          title: "Improved customer satisfaction",
          description:
            "Increasing satisfaction scoresacross consumer & interprise portfolio while also reducing cost. ",
        },
      ],
    },
    {
      name: "AIRLINES/AVIATION",
      logo: IMG_BRAND9,
      logoDark: IMG_BRAND9,
      alt: "AirArabia",
      growth: "1400",
      growthText: " Lift in meal bookings through automated conversions ",
      data: [
        {
          title: "Recommendations to existing customers",
          description:
            "On extra baggage purchase options, meal add-ons, and options to upgrade meals. ",
        },
        {
          title: "Complete booking using chat",
          description:
            "Information related to travel guidelines basis point of origin and arrival. ",
        },
        {
          title: "L1 & L support query resolution",
          description:
            "Related to flight status checks, booking status,transaction queries, refunds etc. Live chat with an agent in case of bot failures. ",
        },
      ],
    },
    {
      name: "AUTOMOBILES",
      logo: IMG_BRAND1,
      logoDark: IMG_BRAND1,
      alt: "Bajaj",
      growth: "14x",
      growthText: "Lift in visitors to lead conversions ",
      data: [
        {
          title: "AI push based",
          description: "Intent- sensing, test drive mechanism. ",
        },
        {
          title: "Omnichannel bots",
          description:
            "Help to optimize all operations, from booking requests. ",
        },
        {
          title: "Virtual assistants",
          description: "Take the request for test drives and schedule ",
        },
      ],
    },
    {
      name: "INSURANCE / CREDIT CARDS",
      logo: IMG_BRAND11,
      logoDark: IMG_BRAND11,
      alt: "Geico",
      growth: "7.2x%",
      growthText: "Increase in Lead Conversions ",
      data: [
        {
          title: "AI-led product recommendation",
          description:
            " Insurance products, plans and premiums basis lead qualification and criteria. ",
        },
        {
          title: "Using human-like",
          description:
            "And automated conversations to capture leads in a non intrusive conversational manner. ",
        },
        {
          title: "Handles complex L2 & L3 queries",
          description:
            "manage over 70% of customer's most complex queries without agent intervention at accuracy levels upwards of 93%. ",
        },
      ],
    },
    {
      name: "CONSUMER DURABLES",
      logo: IMG_BRAND13,
      logoDark: IMG_BRAND13_DARK,
      alt: "Hp",
      growth: "23%",
      growthText: "Higher re-engagement ",
      data: [
        {
          title: "Product discovery",
          description:
            " And awareness using conversations as a lever to drive conversions and sustained engagement. ",
        },
        {
          title: "Acting as a personalized AI",
          description:
            " companion, that accelerates the information gathering journey and guides user towards optimal path. ",
        },
        {
          title: "Using AI",
          description:
            "And Machine learning to predict affinity and propensity ",
        },
      ],
    },
    {
      name: "HEALTHCARE",
      logo: IMG_BRAND12,
      logoDark: IMG_BRAND12,
      alt: "HealthCare",
      growth: "4x",
      growthText: "Improvement in re-visits ",
      data: [
        {
          title: "AI-led patient registration ",
          description: "Appointments, as well medicine discovery. ",
        },
        {
          title: "Insurance coverage",
          description:
            "Qualifications. Consult online. Ecom enabled. Promoting health check-ups. ",
        },
        {
          title: "Conversational AI",
          description:
            "To manage patient query, sent lab reports and also assist with post treatment care. ",
        },
      ],
    },
    {
      name: "EDUCATION TECH",
      logo: IMG_BRAND10,
      logoDark: IMG_BRAND10_DARK,
      alt: "EF",
      growth: "24%",
      growthText: "Lift in leads from social ",
      data: [
        {
          title: "AI-led counselling ",
          description:
            "And Course recommendations.Increased enrollments through on-time discovery and confidence building. ",
        },
        {
          title: "Share relevant course",
          description:
            "Information and double up as a lead capture agent. Student engagements & campus visits. ",
        },
        {
          title: "Student support",
          description: " Document management & progression analysis. ",
        },
      ],
    },
    {
      name: "E-COMMERCE",
      logo: IMG_BRAND14,
      logoDark: IMG_BRAND14_DARK,
      alt: "6thStreet",
      growth: "20x",
      growthText: "ROI over 30 days ",
      data: [
        {
          title: "Conversational AI",
          description: "led product discovery & recommendations. ",
        },
        {
          title: "Convert cold leads",
          description: "To orders with AI-led proactive conversations. ",
        },
        {
          title: "Reward management",
          description: "Delivery tracking, returns management. ",
        },
      ],
    },
    {
      name: "CORE BANKING/FINTECH",
      logo: IMG_BRAND18,
      logoDark: IMG_BRAND18,
      alt: "ICICI",
      growth: "25%",
      growthText: "Reduction in cost per lead ",
      data: [
        {
          title: "Conversational AI-led",
          description: " Product discovery & recommendations. ",
        },
        {
          title: "Lead qualifications",
          description:
            "And conversions. Account management & upsell recommendations ",
        },
        {
          title: "Reduce cx support",
          description: "Costs by upto 80%. Improve CSAT by 8X ",
        },
      ],
    },
  ],
}

export const INDUSTRY_OPTIONS = [
  {
    label: "Banking & Finance",
    value: "banking&Finance",
  },
  {
    label: "Electronics",
    value: "electronics",
  },
  {
    label: "Real Estate",
    value: "realEstate",
  },
  {
    label: "Telecom",
    value: "Telecom",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "AutoMotive",
    value: "autoMotive",
  },
  {
    label: "E Commerce",
    value: "eCommerce",
  },
  {
    label: "Retail",
    value: "retail",
  },
  {
    label: "Travel & Aviation",
    value: "travel&Aviation",
  },
  {
    label: "Others",
    value: "others",
  },
]
